import { CustomDropzone } from 'pages/main/CustomDropzone';
import 'react-dropzone-uploader/dist/styles.css';

export const App = () => {
	return (
		<>
			<CustomDropzone />
			<footer className={'h-20'}>HOLA</footer>
		</>
	);
};
